<template>
  <div class="home-page">
    <navbar @changeLang="onChangeLang"></navbar>
    <div></div>
    <div class="flex-mid">
      <div class="center">
        <div class="greeting mx-auto px-5" style="max-width: 35rem">
          {{ $t("home") }}
        </div>
        <div :class="`consent-dialog2 ${isMouseEnter ? 'show' : ''}`">
          <div class="content">
            <p class="consent-data">{{ $t("consent") }}</p>
          </div>
        </div>
        <div class="homeDesc mt-30 mx-auto px-0" style="max-width: 43rem">
          {{ $t("homeDesc") }}
        </div>
        <div class="termandcond mx-auto" style="max-width: 43rem">
          <div class="usedata">
            <el-checkbox v-model="usingData" />
            <p
              class="ml-10"
              v-on:mouseover="handleMouseEnter"
              v-on:mouseout="handleMouseOut"
            >
              <span>{{ $t("agreeUsingData") }}</span>
              <span
                style="color: #2d7617; text-decoration: underline"
                @click="showConsent"
                >{{ $t("agreeUsingData2") }}</span
              >
              <span>{{ $t("agreeUsingData3") }}</span>

              <span style="margin-left: 0.5rem" @click="showConsent">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="8.5"
                    cy="8.5"
                    r="7.5"
                    fill="white"
                    stroke="#6DB557"
                    stroke-width="1.5"
                  />
                  <path
                    d="M8.57422 5.68896L8.5005 5.68896L8.5005 5.61525L8.57422 5.61525L8.57422 5.68896Z"
                    fill="white"
                    stroke="#6DB557"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.54004 10.8496L8.54004 7.90089"
                    stroke="#6DB557"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </p>
          </div>
          <div class="term">
            <el-checkbox v-model="TermsAndConditions" />
            <p class="ml-10 flex-mid" style="display: inline">
              {{ $t("agreeTermandPrivacy") }}
              <span data-toggle="modal" data-target="#termModal">{{
                $t("TermAndCond")
              }}</span>
              <br />
              <br />
              <span data-toggle="modal" data-target="#privacyModal">{{
                $t("privacyPolicy")
              }}</span>
            </p>
          </div>
        </div>
        <button
          style="margin-top: 10.6rem; width: 100%; max-width: 22rem"
          class="submit-button mx-auto"
          @click="goToRegisterPage()
          "
          :disabled="!(TermsAndConditions)"
        >
          {{ $t("start") }}
        </button>
        <!-- <h4
          class="already"
          style="cursor: pointer"
          @click="$router.push({ path: '/signin', query: { lang: lang } })"
        >
          {{ $t("already") }}
        </h4> -->
      </div>
    </div>
    <div class="flex-mid">
      <img src="@/assets/image/side-image.png" class="image" alt="" />
    </div>
  </div>

  <!-- privacy modal -->
  <div
    class="modal fade"
    id="privacyModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="termModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" style="border-radius: 30px">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span
              aria-hidden="true"
              style="font-size: 40px; padding-right: 10px"
              >&times;</span
            >
          </button>
          <div class="p-20 DBAdmanX">
            <h2>{{ $t("privacytitle") }}</h2>
            <div class="line-terms mt-28 mb-36"></div>
            <div class="termandcond">
              <div v-html="$t('privacy')"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- term modal -->
  <div
    class="modal fade"
    id="termModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="termModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" style="border-radius: 30px">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span
              aria-hidden="true"
              style="font-size: 40px; padding-right: 10px"
              >&times;</span
            >
          </button>
          <div class="p-20 DBAdmanX">
            <h2>{{ $t("termTitle") }}</h2>
            <div class="line-terms mt-28 mb-36"></div>
            <div class="termandcond">
              <div v-html="$t('term')"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- consent modal -->
  <div class="consent-dialog">
    <el-dialog v-model="clickConsent">
      <p class="consent-data" style="word-break: break-word">
        {{ $t("consent") }}
      </p>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue3-i18n";
import Navbar from "@/components/NavbarSTH.vue";

export default defineComponent({
  name: "Home",
  setup() {
    const i18n = useI18n();
    const setLocale = (lang) => {
      i18n.setLocale(lang);
    };
    return {
      setLocale,
    };
  },
  components: {
    Navbar,
  },
  data() {
    return {
      lang: "th",
      usingData: false,
      TermsAndConditions: false,
      hoverConsent: false,
      clickConsent: false,
      tempId: "",
      isMouseEnter: false,
    };
  },
  mounted() {
    this.lang = !this.$route.query.lang ? this.lang : this.$route.query.lang;
  },
  methods: {
    onChangeLang(value) {
      this.lang = value;
      window.localStorage.setItem("lang", value);
    },
    showConsent() {
      // this.hoverConsent = !this.hoverConsent
      this.clickConsent = true;
    },
    handleMouseEnter() {
      this.isMouseEnter = true;
    },
    handleMouseOut() {
      setTimeout(() => {
        this.isMouseEnter = false;
      }, 5000);
    },
    goToRegisterPage(){
      window.localStorage.setItem("disclaimerConsent", JSON.stringify(this.TermsAndConditions));
      window.localStorage.setItem("marketingConsent",JSON.stringify(this.usingData));
      this.$router.push({ path: '/sth/register', query: { lang: this.lang } })
    }
  },
});
</script>

<style lang="scss" scoped>
.greeting {
  font-size: 3.2rem;
}

.homeDesc {
  font-size: 2rem;
  font-weight: 500;
  color: #525252;
}

.home-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 899px auto;

  @media only screen and (max-width: 899px) {
    grid-template-columns: 100vw 0;
  }
}

.already {
  font-size: 1.8rem;
  color: #2d7617;
  margin-top: 20px;
  text-decoration: underline;
}

.already:hover {
  color: #2d7617;
}

.image {
  width: 100%;
  height: 100vh;
  background: gray;
  object-fit: cover;
}

.termandcond {
  font-size: 1.6rem;
  color: #000000;
  margin-top: 2.4rem;
  text-align: center;
  line-height: 2.4rem;
  text-align: left;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #ffffff;

  .usedata {
    display: flex;
  }

  .term {
    display: flex;

    span {
      color: #2d7617;
      text-decoration: underline;
    }
  }
}

.consent-dialog {
  .el-dialog {
    border-radius: 2rem;
  }

  .consent-data {
    font-size: 1.4rem;
    margin: 1.5rem;
  }

  .close-btn {
    margin: auto;
    border: none;
    width: 40%;
    padding: 1rem;
    background: #6db557;
    color: #ffffff;
    border-radius: 1rem;
  }
  @media only screen and (min-width: 800px) {
    display: none;
  }
}
.consent-dialog2 {
  position: absolute;
  display: none;
  width: 600px;
  height: 16rem;
  // background: #F3F3E7;
  margin-left: 1rem;
  border-radius: 17px;
  z-index: 99;
  margin-top: -3rem;
  background-image: url("~@/assets/image/bubble.png");
  background-repeat: no-repeat;
  background-size: 100%;

  @media only screen and (max-width: 800px) {
    display: none;
  }

  .content {
    width: 100%;
    padding: 1rem;

    .consent-data {
      font-size: 13px;
      word-break: break-word;
    }
  }
}

.consent-dialog2.show {
  display: flex;
  @media only screen and (max-width: 800px) {
    display: none;
  }
}
</style>
