<template>
  <div class="relative nav-container">
    <div id="nav" class="nav-bar flex-center">
      <!-- logout button -->
      <!-- <div
        class="row logout-button"
        style="padding-left: 1rem"
        @click="Logout()"
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 17C8 16.4477 7.55228 16 7 16H2V2H7C7.55228 2 8 1.55228 8 1C8 0.447714 7.55228 0 7 0H2C0.895431 0 0 0.895431 0 2V16C0 17.1046 0.895431 18 2 18H7C7.55228 18 8 17.5523 8 17Z"
            fill="#828282"
          />
          <path
            d="M18.7136 9.70055C18.8063 9.6062 18.8764 9.49805 18.9241 9.38278C18.9727 9.26575 18.9996 9.1375 19 9.003L19 9L19 8.997C18.9992 8.74208 18.9016 8.48739 18.7071 8.29289L14.7071 4.29289C14.3166 3.90237 13.6834 3.90237 13.2929 4.29289C12.9024 4.68342 12.9024 5.31658 13.2929 5.70711L15.5858 8H6C5.44771 8 5 8.44771 5 9C5 9.55229 5.44771 10 6 10H15.5858L13.2929 12.2929C12.9024 12.6834 12.9024 13.3166 13.2929 13.7071C13.6834 14.0976 14.3166 14.0976 14.7071 13.7071L18.7064 9.70782L18.7136 9.70055Z"
            fill="#828282"
          />
        </svg>

        <h2
          class="DBAdmanX logout-text"
          style="margin-left: 10px; margin-top: 4px"
        >
          {{ $t("signout") }}
        </h2>
      </div> -->

      <div class="ml-auto">
        <img
          src="@/assets/image/sthlogo.png"
          style="width: 12rem"
          alt="samitivej image"
        />
      </div>
      <div class="ml-auto flex-center">
        <img
          v-if="lang == 'en'"
          src="@/assets/image/eng-lang.png"
          style="width: 3.4rem"
          alt="eng lang"
        />
        <img
          v-else
          src="@/assets/image/thai-lang.png"
          alt="thai lang"
          style="width: 3.4rem"
        />
        <el-select
          v-model="lang"
          class="m-2 ml-12"
          placeholder="Select"
          style="width: 10rem"
        >
          <el-option key="en" :label="$t('english')" value="en"> </el-option>
          <el-option key="th" :label="$t('thai')" value="th"> </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue3-i18n";

export default defineComponent({
  name: "NavbarBookingSTH",
  setup() {
    const i18n = useI18n();
    const setLocale = (lang) => {
      i18n.setLocale(lang);
    };

    return {
      setLocale,
    };
  },
  data() {
    return {
      lang: "th",
    };
  },
  mounted() {
    this.lang = !this.$route.query.lang ? this.lang : this.$route.query.lang;
  },
  watch: {
    lang(value) {
      this.setLocale(value);
      this.$emit("changeLang", value);
    },
  },

  methods: {
    Logout() {
      console.log("sign out");
      window.localStorage.clear();
      this.$router.push({ path: "/", query: { lang: this.lang } });
    },
  },
});
</script>

<style lang="scss" scoped>
.nav-container {
  height: 0px;
}
.nav-bar {
  display: fixed;
  padding: 2.3rem 0rem 0;
  @media only screen and (min-width: 500px) {
    padding: 2.3rem 0rem 0;
  }
}
.logout-button {
  cursor: pointer;
}
.logout-text {
  @media only screen and (max-width: 340px) {
    display: none !important;
  }
}
.el-input__inner {
  background-color: #f3f3e7;
}
.el-select .el-input__inner {
  background-color: #f3f3e7;
  cursor: pointer;
  display: inline-flex;
}
</style>
